<!--阅卷填空题-->
<template>
  <div class="maincontent">
    <div style="display: flex; font-size: 26px; padding: 24px 21px" @click="goback">
      <div style="color: #bababa">&lt;</div>
      <div style="font-weight: bold; color: #000000; margin-left: 17px">
        阅卷
      </div>
    </div>
    <div
      style="
        display: flex;
        font-size: 20px;
        color: #bababa;
        margin-left: 60px;
        margin-right:60px;
        justify-content: space-between;
        margin-bottom: 36px;
        height:40px
      "
    >
     <div style="
        display: flex;
        font-size: 20px;
        color: #bababa;
        
        width: 50%;
        justify-content: space-between;
        margin-bottom: 36px;
      ">
        <div style="display: flex">
        <div>等级：</div>
        <div>答应</div>
      </div>
      <div style="display: flex">
        <div>答题地区：</div>
        <div>杭州</div>
      </div>
      <div style="display: flex">
        <div>答题时间：</div>
        <div>2021年10月1日</div>
      </div>
     </div>
      <el-button type="primary" @click="end">阅卷结束</el-button>
    </div>
    <!--填空题-->
    <div v-if="lists.typeId== 3">
      <div class="title">
        <div class="line"></div>
        <div>填空题</div>
      </div>
      <div style="margin: 24px 0 30px 81px; font-size: 20px">
        <!-- 1.护肤应该先____,然后____,最后____。 -->
        {{lists.questionContent}}
      </div>
      <!--填空题-->
      <div style="display:flex">
        <div  style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 30px 30px 95px;
          flex-direction:column
        ">
          <div style="margin-bottom:30px">参考答案:</div>
          <div>考生答案：</div>
        </div>
        <div style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 0px;
           flex-direction:column;
           
        ">
            <div style="display:flex;justify-content: space-around;margin:30rpx 0"><div  v-for='(item,index) of lists.questionAnswer' :key='index' style="margin:0px 15px">{{item}}</div></div>
            <div style="display:flex;justify-content: space-around;margin:30px 0"><div  v-for='(item,index) of lists.userQuestionAnswer2' :key='index'>{{item.name}}</div></div>
          <div style="display:flex;justify-content: space-around;">

              <div
          style=" display: flex; flex-direction: column"
          v-for="(item, index) in lists.userQuestionAnswer2"
          :key="index"
        >
          <img
            src="../../images/right.png"
            alt=""
            style="width: 34px; height: 34px"
            v-show="item.right == false"
            @click="select(item.right, index, 'right')"
          />
          <img
            src="../../images/selright.png"
            alt=""
            style="width: 34px; height: 34px"
            v-show="item.right == true"
            @click="select(item.right, index, 'right')"
          />
          <img
            src="../../images/wrong.png"
            alt=""
            style="width: 34px; height: 34px; margin-top: 18px"
            v-show="item.wrong == false"
            @click="select(item.wrong, index, 'wrong')"
          />
          <img
            src="../../images/selwrong.png"
            alt=""
            style="width: 34px; height: 34px; margin-top: 18px"
            v-show="item.wrong == true"
            @click="select(item.wrong, index, 'wrong')"
          />
        </div>
          </div>
        </div>
        
      </div>
      
      <div
          style="
            display: flex;
            font-size: 20px;
            justify-content: center;
            margin-top: 101px;
            padding-bottom: 65px;
          "
        >
          <!-- <div class="buttomlr" @click="previousquestion">&lt;</div> -->
          <el-button type='primary' @click="previousquestion">上一题</el-button>
          <div style="line-height: 34px; margin-left: 25px">{{current}}</div>
          <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
            /{{total}}
          </div>
          <el-button type='primary' @click="nextquestion">下一题</el-button>

          <!-- <div class="buttomlr" @click='nextquestion'>&gt;</div> -->
        </div>
      <!-- <div
        style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 95px;
        "
      >
        <div>参考答案：</div>
        <div style="margin: 0 30px" v-for='(item,index) of lists.questionAnswer' :key='index'>{{item}}</div>
      </div>
      <div
        style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 95px;
        "
      >
        <div>考生答案：</div>
        <div style="margin: 0 30px" v-for='(item,index) of lists.userQuestionAnswer2' :key='index'>{{item.name}}</div>
      </div>
      <div
        style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 198px;
        "
      >
        <div
          style="margin: 0 30px; display: flex; flex-direction: column"
          v-for="(item, index) in lists.userQuestionAnswer2"
          :key="index"
        >
          <img
            src="../../images/right.png"
            alt=""
            style="width: 34px; height: 34px"
            v-show="item.right == false"
            @click="select(item.right, index, 'right')"
          />
          <img
            src="../../images/selright.png"
            alt=""
            style="width: 34px; height: 34px"
            v-show="item.right == true"
            @click="select(item.right, index, 'right')"
          />
          <img
            src="../../images/wrong.png"
            alt=""
            style="width: 34px; height: 34px; margin-top: 18px"
            v-show="item.wrong == false"
            @click="select(item.wrong, index, 'wrong')"
          />
          <img
            src="../../images/selwrong.png"
            alt=""
            style="width: 34px; height: 34px; margin-top: 18px"
            v-show="item.wrong == true"
            @click="select(item.wrong, index, 'wrong')"
          />
        </div>
      </div>
      <div>
        <div
          style="
            display: flex;
            font-size: 20px;
            justify-content: center;
            margin-top: 201px;
            padding-bottom: 65px;
          "
        >
          <div class="buttomlr">&lt;</div>
          <div style="line-height: 34px; margin-left: 25px">1</div>
          <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
            /5
          </div>
          <div class="buttomlr">&gt;</div>
        </div>
      </div> -->
    </div>
    <!--主观题-->
    <div v-if="lists.typeId== 4">
      <div class="title">
        <div class="line"></div>
        <div>主观题</div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 81px">
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex; align-items: center">
            <div>{{lists.questionContent}}</div>
            <div style="color: #bababa; margin: 0 12px">({{lists.score}}分)</div>
            <div style="color: #409eff" >
              查看答案<img
                v-show="answer"
                src="../../images/down.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
              <img
                v-show="!answer"
                src="../../images/up.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div style="margin-top: 15px; color: #bababa; font-size: 20px">
            考生答案：
          </div>
          <div
            style="
              width: 902px;
              max-height: 257px;
              overflow: auto;
              border: 1px solid #cacaca;
              padding: 14px;
              margin: 15px 0;
              font-size: 16px;
              line-height: 34px;
              min-height:200px
            "
          >
           {{lists.userQuestionAnswer}}
          </div>
        </div>
        <div style="display:flex;justify-content;justify-content: flex-end;">
          <div
            style="
              width: 902px;
              max-height: 257px;
              overflow: auto;
              border: 1px solid #cacaca;
              padding: 14px;
              margin: 15px 0;
              font-size: 16px;
              line-height: 34px;
              color: #cacaca;
              min-height:200px
            "
            v-show="answer"
          >
           {{lists.questionAnswer}}
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-top: 36px">
          <div style="color: #bababa; font-size: 20px; margin-left: 40px;flex-shrink:0">
            评分：
          </div>
          <div style="display:flex;justify-content:space-between;width:100%">
            <el-input
            v-model="score2"
            placeholder="请输入分数"
            style="width: 139px"
          ></el-input>
          <el-button type="primary" @click='submit'>确认提交</el-button>
          </div>
        </div>
        <div>
          <div
            style="
              display: flex;
              font-size: 20px;
              justify-content: center;
              margin-top: 65px;
              padding-bottom: 65px;
            "
          >
            <!-- <div class="buttomlr" @click="previousquestion">&lt;</div>
            <div style="line-height: 34px; margin-left: 25px">{{current}}</div>
            <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
              /{{total}}
            </div>
            <div class="buttomlr" @click='nextquestion'>&gt;</div> -->
            <el-button type='primary' @click="previousquestion">上一题</el-button>
          <div style="line-height: 34px; margin-left: 25px">{{current}}</div>
          <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
            /{{total}}
          </div>
          <el-button type='primary' @click="nextquestion">下一题</el-button>
          </div>
        </div>
      </div>
    </div>
    <!--看图说话-->
    <div v-if="lists.typeId== 5">
      <div class="title">
        <div class="line"></div>
        <div>主观题</div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 0">
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
            margin: 0 81px;
          "
        >
          <div style="display: flex; align-items: center">
            <div>{{lists.questionContent}}</div>
            <div style="color: #bababa; margin: 0 12px">(10分)</div>
            <div style="color: #409eff" @click="lookanswer">
              查看答案<img
                v-show="answer"
                src="../../images/down.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
              <img
                v-show="!answer"
                src="../../images/up.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            margin: 15px 0;
            justify-content: space-between;
            margin: 24px 93px;
          "
        >
         <img :src="lists.resourceUri" alt=""  style="width: 261px;height: 261px;"/>
         <!-- <el-carousel trigger="click" >
          <el-carousel-item v-for="item in 4" :key="item">
            <el-carousel-item>
           
          </el-carousel-item>
        </el-carousel> -->
          <!-- <div
            style="
              width: 261px;
              height: 261px;
              background: pink;
              position: relative;
            "
          >
            <div
              style="
                width: 100%;
                height: 32px;
                background: #bababa;
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
              "
            >
              <div>&lt;</div>
              <div style="display: flex; margin: 0 23px">
                <div>{{current}}</div>
                <div>/{{total}}</div>
              </div>
              <div>&gt;</div>
            </div>
          </div> -->
          <div>
            <div style="font-size: 20px; color: #bababa; margin-bottom: 10px">
              考生答案：
            </div>
            <div
              style="
                width: 695px;
                max-height: 232px;
                overflow: auto;
                border: 1px solid #cacaca;
                font-size: 16px;
                line-height: 34px;
              "
            >
              <div style="margin: 12px;min-height:200px">
               {{lists.userQuestionAnswer}}
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end; margin: 0 89px">
          <div
            style="
              width: 100%;
              max-height: 232px;
              overflow: auto;
              border: 1px solid #cacaca;
              padding: 14px;
              margin: 15px 0;
              font-size: 16px;
              line-height: 34px;
              color: #cacaca;
              min-height:200px
            "
            v-show="answer"
          >
            {{lists.questionAnswer}}
          </div>
        </div>
        <div style="display: flex; align-items: center; margin: 36px 90px 0">
          <div style="color: #bababa; font-size: 20px;;flex-shrink:0">
            评分：
          </div>
          <div style="display:flex;justify-content:space-between;width:100%">
            <el-input
            v-model="score2"
            placeholder="请输入分数"
            style="width: 139px"
          ></el-input>
          <el-button type="primary" @click='submit'>确认提交</el-button>
          </div>
        </div>
        <div>
          <div
            style="
              display: flex;
              font-size: 20px;
              justify-content: center;
              margin-top: 65px;
              padding-bottom: 65px;
            "
          >
            <!-- <div class="buttomlr" @click="previousquestion">&lt;</div>
            <div style="line-height: 34px; margin-left: 25px">{{current}}</div>
            <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
              /{{total}}
            </div>
            <div class="buttomlr" @click='nextquestion'>&gt;</div> -->
            <el-button type='primary' @click="previousquestion">上一题</el-button>
          <div style="line-height: 34px; margin-left: 25px">{{current}}</div>
          <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
            /{{total}}
          </div>
          <el-button type='primary' @click="nextquestion">下一题</el-button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {questionExamList,questionExamupdate,userQuestiongetById,endPaper} from '../../apis/index'
export default {
  data() {
    return {
      id:'',//试题id
      nextId: '',//下一题id
      previousId: '',//上一题id
      score:'',//试题分值
      userRightKey:'',
      current:1,
      total:10,
      lists:{},
      pageIndex:1,
      pageSize:1,
      paperId:'',
      questionTypes:[3,4,5],
      score2:'',
      answer: true,
      status: 1, //1 填空 2 主观题 3 看图说话
      list: [],
    };
  },
  created(){
    let id=this.$route.query.id
    this.paperId=id
    this.questionList()
  },
  methods: {
    goback(){//返回
      this.$router.go(-1)
    },
   async end(){
     const {data}=await endPaper({id:this.paperId})
     if(data.code==200){
       this.$message.success('提交成功')
       setTimeout(()=>{
         this.$router.go('-1')
       },100)
     }
    },
     async previousquestion(){//上一题
    //  console.log(this.lists.userQuestionAnswer2)
    //  console.log(this.lists.questionAnswer)
    //  return
     const {data}=await userQuestiongetById({id:this.previousId})
     console.log(data)
     if(data.code==200){
        let list=data.data
       let userQuestionAnswer=[]
        let userRightKey=[]
       var obj={}
       if(list.typeId==3){//填空题
       if(list.userRightKey==''){
          list.questionAnswer=list.questionAnswer.split('&&&')
          userQuestionAnswer=list.userQuestionAnswer.split('&&&')
         let userQuestionAnswer2= userQuestionAnswer.map(item => {
            return obj={
              name:item,
              right: false,
              wrong: false,
             }
          });
          list.userQuestionAnswer2=userQuestionAnswer2
           this.lists=list
          this.total=this.total
          this.current=Number(this.current)-1
          this.id=data.data.id
          this.nextId=data.data.nextId
          this.previousId=data.data.previousId
          this.score=data.data.score
           
       }else{
         if(list.userQuestionAnswer){
           userQuestionAnswer=list.userQuestionAnswer.split('&&&')
           userRightKey=(list.userRightKey).split('').splice(0,list.questionAnswer.length)

         }else{
           userQuestionAnswer=[]
         }
          list.questionAnswer=list.questionAnswer.split('&&&')
         
          // list.questionAnswer=list.questionAnswer.split('&&&')
          // userQuestionAnswer=list.userQuestionAnswer.split('&&&')
          // let userRightKey=(list.userRightKey).split('').splice(0,list.questionAnswer.length)
          
        let arr=[]
        userQuestionAnswer.map((item,index)=>{
          userRightKey.map((item2,index2)=>{
           if(item2==1 && index==index2){
            arr.push({
              name:item,
              right: true,
              wrong: false,
             })
            
           }else if(item2==0 && index==index2){
             arr.push({
              name:item,
              right: false,
              wrong: true,
             })
           }
            
          })
        })
          list.userQuestionAnswer2=arr
          this.lists=list
          this.total=this.total
          this.current=Number(this.current)-1
          this.id=data.data.id
          this.nextId=data.data.nextId
          this.previousId=data.data.previousId
          this.score=data.data.score
          
         
       }
       }else if(list.typeId==4){//主观题
        this.lists=list
        this.id=list.id
        this.nextId=list.nextId
        this.previousId=list.previousId
        this.score=list.score
        this.score2=list.userScore || ''
        this.current=Number(this.current)-1
       }else if(list.typeId==5){//看图说话
        this.lists=list
        this.id=list.id
        this.nextId=list.nextId
        this.previousId=list.previousId
        this.score=list.score
        this.score2=list.userScore || ''
        this.current=Number(this.current)-1
       }
    }
   },
   async nextquestion(){//下一题
     const {data}=await userQuestiongetById({id:this.nextId})
     console.log(data)
     if(data.code==200){
        let list=data.data
       let userQuestionAnswer=[]
        let userRightKey=[]
       var obj={}
       if(list.typeId==3){//填空题
       if(list.userRightKey==''){
          list.questionAnswer=list.questionAnswer.split('&&&')
          userQuestionAnswer=list.userQuestionAnswer.split('&&&')
         let userQuestionAnswer2= userQuestionAnswer.map(item => {
            return obj={
              name:item,
              right: false,
              wrong: false,
             }
          });
          list.userQuestionAnswer2=userQuestionAnswer2
           this.lists=list
          this.total=this.total
          this.current=Number(this.current)+1
          this.id=data.data.id
          this.nextId=data.data.nextId
          this.previousId=data.data.previousId
          this.score=data.data.score
           
       }else{
          // list.questionAnswer=list.questionAnswer.split('&&&')
          // userQuestionAnswer=list.userQuestionAnswer.split('&&&')
          // let userRightKey=(list.userRightKey).split('').splice(0,list.questionAnswer.length)
          if(list.userQuestionAnswer){
           userQuestionAnswer=list.userQuestionAnswer.split('&&&')
           userRightKey=(list.userRightKey).split('').splice(0,list.questionAnswer.length)

         }else{
           userQuestionAnswer=[]
         }
          list.questionAnswer=list.questionAnswer.split('&&&')
         
          
        let arr=[]
        userQuestionAnswer.map((item,index)=>{
          userRightKey.map((item2,index2)=>{
           if(item2==1 && index==index2){
            arr.push({
              name:item,
              right: true,
              wrong: false,
             })
            
           }else if(item2==0 && index==index2){
             arr.push({
              name:item,
              right: false,
              wrong: true,
             })
           }
            
          })
        })
          list.userQuestionAnswer2=arr
          this.lists=list
          this.total=this.total
          this.current=Number(this.current)+1
          this.id=data.data.id
          this.nextId=data.data.nextId
          this.previousId=data.data.previousId
          this.score=data.data.score
          
         
       }
       }else if(list.typeId==4){//主观题
        this.lists=list
        this.id=list.id
        this.nextId=list.nextId
        this.previousId=list.previousId
        this.score=list.score
        this.score2=list.userScore || ''
        this.current=Number(this.current)+1
       }else if(list.typeId==5){//看图说话
        this.lists=list
        this.id=list.id
        this.nextId=list.nextId
        this.previousId=list.previousId
        this.score=list.score
        this.score2=list.userScore || ''
        this.current=Number(this.current)+1
       }
    }
   },
   async questionList(){
     const params={
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        paperId:this.paperId,
        questionTypes:this.questionTypes
     }
     const {data}=await questionExamList(params)
     if(data.code==200){
       let list=data.data.records[0]
       let userQuestionAnswer=[]
       let userRightKey=[]
       let obj={}
       if(list.typeId==3){//填空题
       if(list.userRightKey==''){
          list.questionAnswer=list.questionAnswer.split('&&&')
          userQuestionAnswer=list.userQuestionAnswer.split('&&&')
         let userQuestionAnswer2= userQuestionAnswer.map(item => {
            return obj={
              name:item,
              right: false,
              wrong: false,
             }
          });
          list.userQuestionAnswer2=userQuestionAnswer2
           this.lists=list
          this.total=data.data.total
          this.current=data.data.current
          this.id=data.data.records[0].id
          this.nextId=data.data.records[0].nextId
          this.previousId=data.data.records[0].previousId
          this.score=data.data.records[0].score
           
       }else{
         if(list.userQuestionAnswer){
           userQuestionAnswer=list.userQuestionAnswer.split('&&&')
           userRightKey=(list.userRightKey).split('').splice(0,list.questionAnswer.length)

         }else{
           userQuestionAnswer=[]
         }
          list.questionAnswer=list.questionAnswer.split('&&&')
          
          
          console.log(userQuestionAnswer)
          console.log(userRightKey)
        //  console.log(userQuestionAnswer2)
        let arr=[]
        let userQuestionAnswer2=userQuestionAnswer.map((item,index)=>{
          userRightKey.map((item2,index2)=>{
           if(item2==1 && index==index2){
            arr.push({
              name:item,
              right: true,
              wrong: false,
             })
            
           }else if(item2==0 && index==index2){
             arr.push({
              name:item,
              right: false,
              wrong: true,
             })
           }
            
          })
        })
          list.userQuestionAnswer2=arr
          this.lists=list
          this.total=data.data.total
          this.current=data.data.current
          this.id=data.data.records[0].id
          this.nextId=data.data.records[0].nextId
          this.previousId=data.data.records[0].previousId
          this.score=data.data.records[0].score
           
         
       }
         
       }
      
     }
    
    },
    lookanswer() {
      this.answer = !this.answer;
    },
    submit(){//提交
      const params={
          id:this.id,
          nextId:this.nextId,
          score:this.score,
          userScore:this.score2,
        }
        this.update(params)
    },
   async update(params){
     const {data}=await questionExamupdate(params)
    
     if(data.code==200){
        let list=data.data
        if(list==null){
          this.end()
          return 
        }
        this.$message.success('提交成功')
      //  let userQuestionAnswer=[]
      //  var obj={}
      //  if(list.typeId==3){//填空题
      //  if(list.userRightKey==''){
      //     list.questionAnswer=list.questionAnswer.split('&&&')
      //     userQuestionAnswer=list.userQuestionAnswer.split('&&&')
      //    let userQuestionAnswer2= userQuestionAnswer.map(item => {
      //       return obj={
      //         name:item,
      //         right: false,
      //         wrong: false,
      //        }
      //     });
      //     list.userQuestionAnswer2=userQuestionAnswer2
      //      this.lists=list
      //     this.total=this.total
      //     this.current=Number(this.current)+1
      //     this.id=data.data.records[0].id
      //     this.nextId=data.data.records[0].nextId
      //     this.previousId=data.data.records[0].previousId
      //     this.score=data.data.records[0].score
           
      //  }else{
      //     list.questionAnswer=list.questionAnswer.split('&&&')
      //     userQuestionAnswer=list.userQuestionAnswer.split('&&&')
      //     let userRightKey=(list.userRightKey).split('').splice(0,list.questionAnswer.length)
          
      //   let arr=[]
      //   userQuestionAnswer.map((item,index)=>{
      //     userRightKey.map((item2,index2)=>{
      //      if(item2==1 && index==index2){
      //       arr.push({
      //         name:item,
      //         right: true,
      //         wrong: false,
      //        })
            
      //      }else if(item2==0 && index==index2){
      //        arr.push({
      //         name:item,
      //         right: false,
      //         wrong: true,
      //        })
      //      }
            
      //     })
      //   })
      //     list.userQuestionAnswer2=arr
      //     this.lists=list
      //     this.total=this.total
      //     this.current=Number(this.current)+1
      //     this.id=data.data.id
      //     this.nextId=data.data.nextId
      //     this.previousId=data.data.previousId
      //     this.score=data.data.score
          
         
      //  }
      //   //   list.questionAnswer=list.questionAnswer.split('&&&')
      //   //   userQuestionAnswer=list.userQuestionAnswer.split('&&&')
      //   //  let userQuestionAnswer2= userQuestionAnswer.map(item => {
      //   //     return obj={
      //   //       name:item,
      //   //       right: false,
      //   //       wrong: false,
      //   //      }
      //   //   });
      //   //   list.userQuestionAnswer2=userQuestionAnswer2
      //  }else if(list.typeId==4){//主观题
      //   this.lists=list
      //   this.id=list.id
      //   this.nextId=list.nextId
      //   this.previousId=list.previousId
      //   this.score=list.score
      //   this.score2=list.userScore || ''
      //   this.current=Number(this.current)+1
      //  }else if(list.typeId==5){//看图说话
      //   this.lists=list
      //   this.id=list.id
      //   this.nextId=list.nextId
      //    this.previousId=list.previousId
      //   this.score=list.score
      //   this.score2=list.userScore || ''
      //   this.current=Number(this.current)+1
      //  }

     }
    },
    select(ischeched, index, name) {
      let list = this.lists;
      if (name == "right") {
        list.userQuestionAnswer2[index].right = !list.userQuestionAnswer2[index].right;
        list.userQuestionAnswer2[index].wrong = false;
        this.lists = list;
      } else {
        list.userQuestionAnswer2[index].wrong = !list.userQuestionAnswer2[index].wrong;
        list.userQuestionAnswer2[index].right = false;
        this.lists = list;
      }
      let obj=this.lists.userQuestionAnswer2
      let i=0
      let succquestion=0
      let str=''
      obj.map(item=>{
        if(item.right || item.wrong){
         i+=1
        }
        if(item.right){
         succquestion+=1
         str+=1
        }else{
          str+=0
        }
      })
      let len=8-obj.length
      for(let i=0;i<len;i++){
        str+='0'
      }
      this.userRightKey=str
      let userScore
      if(obj.length==i){//全选自动提交
      if(succquestion==obj.length){//全对就不计算
        userScore=this.score
      }else{
      userScore=((this.score/obj.length)*succquestion).toFixed(2)
      }
      console.log(this.id)
      console.log(this.nextId)
        const params={
          id:this.id,
          nextId:this.nextId,
          score:this.score,
          userScore:userScore,
          userRightKey:this.userRightKey
        }
        this.update(params)
      }
     
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
  text-align: center;
  color: red;
  // font-size: 20px;
}
.buttomlr {
  width: 34px;
  height: 34px;
  border: 1px solid #e4e4e4;
  line-height: 34px;
  text-align: center;
  border-radius: 6px;
  color: #e4e4e4;
}
.maincontent {
  width: 1200px;
  background: #fff;
  border-radius: 14px;
  margin: 13px 0;
  .title {
    display: flex;
    margin-left: 60px;
    align-items: center;
    .line {
      width: 9px;
      height: 22px;
      background: #409eff;
      border-radius: 6px;
    }
  }
  .title > div:last-child {
    font-size: 20px;
    font-weight: bold;
    margin-left: 11px;
  }
}
</style>
